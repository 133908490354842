body {
  background-color: #000000f1;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #000000f1;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.1;
  overflow: hidden;
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image:
    radial-gradient(circle at 25% 25%, rgba(59, 130, 246, 0.2) 1px, transparent 1px),
    radial-gradient(circle at 75% 75%, rgba(59, 130, 246, 0.2) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.4;
  z-index: 2;
  pointer-events: none;
}

.background-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
  transform: scale(1.1);
  opacity: 0.8;
}

.landing-container {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  margin-top: 120px;
}

.content-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  animation: fadeIn 1.5s ease-in;
  position: relative;
  scroll-behavior: smooth;
}

.content-wrapper::before {
  content: '';
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 60%;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.1) 0%, transparent 70%);
  filter: blur(60px);
  z-index: -1;
  opacity: 0.7;
  transform: translateZ(0);
}

.main-logo {
  max-width: min(700px, 90vw);
  width: 90%;
  height: auto;
  filter: drop-shadow(0 0 30px rgba(59, 130, 246, 0.4));
  animation: logoPulse 4s ease-in-out infinite;
  transform-origin: center;
  margin: 1rem auto;
  display: block;
  will-change: transform;
}

.value-proposition {
  width: 100%;
  padding: 4rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0;
  background: linear-gradient(180deg, rgba(15, 23, 42, 0.9) 0%, rgba(17, 24, 39, 0.95) 100%);
  border-radius: 0 0 20px 20px;
  z-index: 4;
}

.main-heading {
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 900;
  margin-bottom: 2.5rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-shadow: 0 0 20px rgba(59, 130, 246, 0.3);
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.01em;
  animation: fadeInUp 1.2s ease-out forwards;
  position: relative;
}

.main-heading::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 2px;
  background: linear-gradient(90deg,
      transparent 0%,
      rgba(59, 130, 246, 0.7) 50%,
      transparent 100%);
  border-radius: 2px;
  opacity: 0.6;
}

.main-heading strong {
  position: relative;
  display: inline-block;
  padding: 0.1em 0.4em;
  margin: 0.15em 0.05em;
  transition: all 0.4s ease;
  color: white;
  font-weight: 900;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  transform: translateY(0);
  border-radius: 6px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  animation: emphasize 5s infinite alternate ease-in-out;
}

@keyframes emphasize {
  0%,
  20% {
    filter: brightness(1);
    transform: scale(1);
  }

  5% {
    filter: brightness(1.2);
    transform: scale(1.05);
  }
}

.main-heading strong:nth-of-type(1) {
  animation-delay: 0s;
  background-color: rgba(59, 130, 246, 0.9);
  box-shadow: 0 0 25px rgba(59, 130, 246, 0.4);
}

.main-heading strong:nth-of-type(2) {
  animation-delay: 1s;
  background-color: rgba(16, 185, 129, 0.9);
  box-shadow: 0 0 25px rgba(16, 185, 129, 0.4);
}

.main-heading strong:nth-of-type(3) {
  animation-delay: 2s;
  background-color: rgba(245, 158, 11, 0.9);
  box-shadow: 0 0 25px rgba(245, 158, 11, 0.4);
}

.main-heading strong:nth-of-type(4) {
  animation-delay: 3s;
  background-color: rgba(239, 68, 68, 0.9);
  box-shadow: 0 0 25px rgba(239, 68, 68, 0.4);
}

.main-heading strong:nth-of-type(5) {
  animation-delay: 4s;
  background-color: rgba(139, 92, 246, 0.9);
  box-shadow: 0 0 25px rgba(139, 92, 246, 0.4);
}

.main-heading strong:hover {
  transform: translateY(-3px) scale(1.05);
  filter: brightness(1.2);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 3rem;
}

.feature-card {
  background: rgba(20, 20, 35, 0.6);
  border-radius: 16px;
  padding: 2.5rem;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(59, 130, 246, 0.15);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(59, 130, 246, 0.1) inset;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg,
      rgba(59, 130, 246, 0.08) 0%,
      rgba(59, 130, 246, 0) 70%);
  z-index: -1;
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.feature-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg,
      transparent 0%,
      rgba(59, 130, 246, 0.5) 50%,
      transparent 100%);
  opacity: 0.2;
  transform: scaleX(0.3);
  transform-origin: center;
  transition: all 0.5s ease;
}

.feature-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(59, 130, 246, 0.2) inset,
    0 0 30px rgba(59, 130, 246, 0.15) inset;
  border-color: rgba(59, 130, 246, 0.3);
}

.feature-card:hover::before {
  opacity: 0.8;
}

.feature-card:hover::after {
  opacity: 0.7;
  transform: scaleX(0.8);
}

.feature-card:hover .feature-icon {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.25),
    0 0 0 1px rgba(59, 130, 246, 0.3) inset;
  background-color: rgba(59, 130, 246, 0.2);
}

.feature-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto 1.5rem;
  background-color: rgba(59, 130, 246, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(59, 130, 246, 0.2) inset;
}

.feature-icon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55%;
  opacity: 0.95;
  transition: all 0.3s ease;
}

.power-icon::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%233B82F6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 10V3L4 14h7v7l9-11h-7z'%3E%3C/path%3E%3C/svg%3E");
}

.platform-icon::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%233B82F6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z'%3E%3C/path%3E%3C/svg%3E");
}

.feature-card h3 {
  font-size: clamp(1.3rem, 3vw, 1.6rem);
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #ffffff, #e0e7ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.feature-card p {
  font-size: clamp(1.1rem, 2vw, 1.25rem);
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.92);
  margin: 0 0 2.5rem;
  text-align: center;
  max-width: 700px;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.feature-card p:last-of-type {
  margin-bottom: 0;
}

/* Call to Action Container - Emphasized */
.cta-container {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto 0;
  padding: 3rem;
  background: linear-gradient(135deg, rgba(13, 17, 23, 0.85), rgba(30, 41, 59, 0.85));
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4),
    0 0 0 1px rgba(59, 130, 246, 0.3) inset,
    0 0 40px rgba(59, 130, 246, 0.2) inset;
  text-align: center;
  position: relative;
  overflow: hidden;
  transform: translateY(0);
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  animation: ctaEntrance 1s cubic-bezier(0.2, 0.9, 0.4, 1.0) 0.5s both,
    ctaPulse 4s ease-in-out infinite alternate;
}

/* Interactive arrows container */
.cta-button-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

/* Left arrow animation */
.arrow-left {
  position: absolute;
  top: 50%;
  right: calc(100% + 40px);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  opacity: 0;
  animation: arrowBounceLeft 2s ease-in-out infinite;
}

/* Right arrow animation */
.arrow-right {
  position: absolute;
  top: 50%;
  left: calc(100% + 40px);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  opacity: 0;
  animation: arrowBounceRight 2s ease-in-out infinite;
}

/* Arrow styling */
.arrow-left svg, .arrow-right svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 0 8px rgba(59, 130, 246, 0.7));
}

/* Animation for left arrow */
@keyframes arrowBounceLeft {
  0%, 100% {
    opacity: 0;
    transform: translateY(-50%) translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-50%) translateX(15px);
  }
}

/* Animation for right arrow */
@keyframes arrowBounceRight {
  0%, 100% {
    opacity: 0;
    transform: translateY(-50%) translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-50%) translateX(-15px);
  }
}

/* Adjust arrow animations for hovering the CTA container */
.cta-container:hover .arrow-left {
  animation: arrowBounceLeft 1.5s ease-in-out infinite;
}

.cta-container:hover .arrow-right {
  animation: arrowBounceRight 1.5s ease-in-out infinite;
}

.cta-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.18) 0%, transparent 70%);
  filter: blur(40px);
  z-index: -1;
  opacity: 0.8;
  transform: translateZ(0);
}

.cta-container::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg,
      rgba(59, 130, 246, 0.5),
      rgba(59, 130, 246, 0.1),
      rgba(59, 130, 246, 0.5));
  z-index: -2;
  border-radius: 16px;
  filter: blur(15px);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cta-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(59, 130, 246, 0.4) inset,
    0 0 50px rgba(59, 130, 246, 0.3) inset;
}

.cta-container:hover::after {
  opacity: 0.5;
}

.cta-text {
  font-size: clamp(1.3rem, 3vw, 1.8rem);
  font-weight: 700;
  margin-bottom: 2rem;
  color: white;
  line-height: 1.4;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  background: linear-gradient(90deg, #ffffff 0%, #e0e7ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textShimmer 3s infinite alternate;
}

@keyframes textShimmer {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes ctaPulse {
  0% {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4),
      0 0 0 1px rgba(59, 130, 246, 0.3) inset,
      0 0 40px rgba(59, 130, 246, 0.2) inset;
  }

  100% {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5),
      0 0 0 1px rgba(59, 130, 246, 0.5) inset,
      0 0 60px rgba(59, 130, 246, 0.4) inset;
  }
}

.cta-button {
  position: relative;
  padding: clamp(1.2rem, 2vw, 1.4rem) clamp(3rem, 4vw, 4.5rem);
  font-size: clamp(1.2rem, 2vw, 1.4rem);
  font-weight: 700;
  color: #fff;
  background: linear-gradient(135deg, #3B82F6, #2563EB, #1E40AF, #3B82F6);
  background-size: 300% 300%;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.6),
    0 5px 20px rgba(0, 0, 0, 0.3),
    0 0 0 2px rgba(255, 255, 255, 0.15) inset;
  width: fit-content;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  animation: buttonGradient 8s ease infinite,
    buttonPulse 3s infinite alternate,
    buttonAttention 8s 2s ease-in-out infinite;
  transform-style: preserve-3d;
  will-change: transform, box-shadow;
  z-index: 20;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(45deg, #3B82F6, #10B981, #3B82F6);
  background-size: 400% 400%;
  animation: buttonGradient 8s ease infinite;
  border-radius: 50px;
  z-index: -1;
  opacity: 0.8;
  filter: blur(15px);
  transition: all 0.6s ease;
}

.cta-button::after {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border-radius: 50px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
  opacity: 0;
  animation: ringPulse 2s ease-in-out 1.5s infinite;
  z-index: -2;
}

.cta-button:hover,
.cta-button:focus {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 15px 50px rgba(59, 130, 246, 0.8),
    0 0 30px rgba(59, 130, 246, 0.6),
    0 0 0 2px rgba(255, 255, 255, 0.2) inset;
  outline: none;
  background-size: 200% 200%;
  animation: buttonGradient 4s ease infinite;
}

.cta-button:active {
  transform: translateY(2px) scale(0.98);
  box-shadow: 0 5px 15px rgba(59, 130, 246, 0.4),
    0 0 10px rgba(59, 130, 246, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

/* Hide the secondary CTA since we've added one to the value proposition section */
.signup-section {
  display: none;
}

/* Animation Keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ctaEntrance {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes buttonAttention {
  0%,
  100% {
    transform: scale(1);
  }

  2%,
  4% {
    transform: scale(1.07);
  }

  3% {
    transform: scale(1.04);
  }

  5% {
    transform: scale(1);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .models-advantages {
    padding: 2rem 1rem 1.5rem;
  }

  .main-heading {
    font-size: clamp(1.7rem, 5vw, 2.2rem);
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  .main-heading strong {
    display: inline-block;
    padding: 0.1em 0.3em;
    margin: 0.1em 0.05em;
  }

  .value-proposition {
    padding: 3rem 1rem;
  }

  .features-grid {
    gap: 1.5rem;
  }

  .feature-card {
    padding: 2rem 1.5rem;
  }

  .feature-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 1.2rem;
  }

  .feature-card h3 {
    font-size: 1.3rem;
  }

  .feature-card p {
    font-size: 1.05rem;
    margin-bottom: 2rem;
  }

  .cta-container {
    padding: 2rem 0;
    margin-top: 1.5rem;
  }

  .cta-text {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }

  .cta-button {
    padding: 1.1rem 2.5rem;
    font-size: 1.1rem;
    width: 100%;
    max-width: 300px;
  }

  .arrow-left {
    right: calc(100% + 20px);
    width: 30px;
    height: 30px;
  }
  
  .arrow-right {
    left: calc(100% + 20px);
    width: 30px;
    height: 30px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes logoPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

/* Loading screen */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000f1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #3B82F6;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Models Advantages Section - First part of the page */
.models-advantages {
  width: 100%;
  padding: 3rem 1rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  background: linear-gradient(180deg, rgba(13, 15, 25, 0.8) 0%, rgba(15, 23, 42, 0.9) 100%);
  border-radius: 20px;
  overflow: hidden;
  z-index: 5;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(59, 130, 246, 0.2) inset;
}

.models-advantages::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.15) 0%, transparent 70%);
  filter: blur(40px);
  z-index: -1;
  pointer-events: none;
  animation: pulseGradient 8s ease-in-out infinite alternate;
}

@keyframes pulseGradient {
  0% {
    opacity: 0.7;
    background-position: 0% 0%;
  }

  100% {
    opacity: 1;
    background-position: 100% 100%;
  }
}

/* Scroll Indicator */
.scroll-indicator {
  position: relative;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.8;
  transition: all 0.3s ease;
  animation: fadeIn 1s ease-out 1.5s both;
  cursor: pointer;
  padding: 15px;
  border-radius: 50px;
}

.scroll-indicator:hover {
  opacity: 1;
  background: rgba(59, 130, 246, 0.1);
  transform: translateY(-3px);
}

.scroll-indicator:active {
  transform: translateY(1px);
}

.scroll-indicator:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.scroll-arrow {
  width: 30px;
  height: 30px;
  border-right: 2px solid rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  transform: rotate(45deg);
  margin-bottom: 0.5rem;
  animation: scrollBounce 2s infinite;
  transition: border-color 0.3s ease;
}

.scroll-indicator:hover .scroll-arrow {
  border-right: 2px solid rgba(255, 255, 255, 1);
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  animation: scrollBounce 1s infinite;
}

.scroll-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.05em;
  font-weight: 500;
  transition: color 0.3s ease;
}

.scroll-indicator:hover .scroll-text {
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 0 10px rgba(59, 130, 246, 0.5);
}

@keyframes scrollBounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(45deg);
  }

  40% {
    transform: translateY(-10px) rotate(45deg);
  }

  60% {
    transform: translateY(-5px) rotate(45deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}

/* Promotional banner */
.promo-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 800px;
  background: rgba(20, 20, 30, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(59, 130, 246, 0.3) inset,
    0 0 20px rgba(59, 130, 246, 0.3);
  z-index: 100;
  animation: promoBannerEnter 0.5s cubic-bezier(0.2, 0.9, 0.3, 1.0) forwards,
    promoBannerGlow 3s ease-in-out 1s infinite alternate;
  border: 1px solid rgba(59, 130, 246, 0.3);
}

@keyframes promoBannerGlow {
  0% {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3),
      0 0 0 1px rgba(59, 130, 246, 0.3) inset,
      0 0 20px rgba(59, 130, 246, 0.3);
  }

  100% {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3),
      0 0 0 1px rgba(59, 130, 246, 0.5) inset,
      0 0 40px rgba(59, 130, 246, 0.5);
  }
}

.promo-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  flex: 1;
}

.promo-text {
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  color: #fff;
  margin-right: 10px;
  font-weight: 500;
}

.promo-button {
  background: linear-gradient(135deg, #3B82F6, #2563EB);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  animation: promoBtnPulse 2s infinite alternate;
}

@keyframes promoBtnPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  100% {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3),
      0 0 15px rgba(59, 130, 246, 0.5);
  }
}

.promo-button:hover {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(59, 130, 246, 0.5);
  background: linear-gradient(135deg, #4B92FF, #3B82F6);
  animation: none;
}

.promo-close {
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.promo-close:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

@keyframes promoBannerEnter {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@media (max-width: 600px) {
  .promo-banner {
    padding: 12px 15px;
    top: 15px;
    width: 92%;
  }

  .promo-content {
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .promo-text {
    margin-right: 0;
    font-size: 0.9rem;
    flex-basis: 100%;
    margin-bottom: 5px;
  }

  .promo-button {
    padding: 8px 15px;
    font-size: 0.9rem;
    width: 100%;
  }

  .landing-container {
    margin-top: 120px;
    /* Adjusted for the taller banner on mobile */
    padding-top: 0;
  }

  .promo-close {
    position: absolute;
    top: 8px;
    right: 8px;
    margin: 0;
  }
}

/* Adjust the landing container margin on mobile */
@media (max-width: 600px) {
  .landing-container {
    margin-top: 100px;
    /* More space on mobile for the wrapped banner content */
  }
}

.contact-link {
  color: white;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 2rem;
  display: inline-block;
  background: rgba(59, 130, 246, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.contact-link:hover {
  background: rgba(59, 130, 246, 0.2);
  border-color: rgba(59, 130, 246, 0.4);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 
              0 0 0 1px rgba(59, 130, 246, 0.2) inset,
              0 0 20px rgba(59, 130, 246, 0.2);
  transform: translateY(-2px);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  animation: modalBgFadeIn 0.4s ease-out forwards;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal-content {
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  background: linear-gradient(135deg, rgba(15, 23, 42, 0.95), rgba(30, 41, 59, 0.95));
  border-radius: 20px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.6),
              0 0 0 1px rgba(59, 130, 246, 0.3) inset,
              0 0 50px rgba(59, 130, 246, 0.4);
  overflow: hidden;
  padding-bottom: 20px;
  transform: scale(0.95);
  opacity: 0;
  animation: modalContentEnter 0.5s 0.1s ease-out forwards, modalPulse 6s ease-in-out infinite alternate;
  border: 1px solid rgba(59, 130, 246, 0.3);
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modal-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 10%, rgba(59, 130, 246, 0.3) 0%, transparent 70%);
  pointer-events: none;
  opacity: 0.7;
  z-index: -1;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  backdrop-filter: blur(5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.modal-close:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(59, 130, 246, 0.4) 0%, transparent 70%);
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;
  transform: scale(0.5);
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3), 
              0 0 20px rgba(59, 130, 246, 0.4);
  border-color: rgba(255, 255, 255, 0.3);
}

.modal-close:hover:before {
  opacity: 1;
  transform: scale(1.5);
}

.modal-close:active {
  transform: rotate(90deg) scale(0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3), 
              0 0 10px rgba(59, 130, 246, 0.3);
}

/* Interactive glow effect on hover */
.modal-content:hover {
  box-shadow: 0 25px 80px rgba(0, 0, 0, 0.7),
              0 0 0 1px rgba(59, 130, 246, 0.5) inset,
              0 0 80px rgba(59, 130, 246, 0.6);
}

/* Enhance modal header with decorative elements */
.modal-header {
  padding: 30px 30px 20px;
  text-align: center;
  background: linear-gradient(180deg, rgba(13, 17, 23, 0.8), transparent);
  border-bottom: 1px solid rgba(59, 130, 246, 0.2);
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
}

.modal-header:before {
  content: '';
  position: absolute;
  top: -100px;
  left: 50%;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(59, 130, 246, 0.4) 0%, transparent 70%);
  transform: translateX(-50%);
  filter: blur(40px);
  opacity: 0.6;
  pointer-events: none;
}

.modal-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%, 
    rgba(59, 130, 246, 0.8) 50%, 
    transparent 100%);
  animation: shimmerLine 3s infinite;
}

@keyframes shimmerLine {
  0% {
    background-position: -100% 0;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    background-position: 200% 0;
    opacity: 0.5;
  }
}

.modal-header h2 {
  margin: 0 0 10px;
  font-size: 2rem;
  font-weight: 700;
  color: white;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  background: linear-gradient(90deg, #ffffff 0%, #e0e7ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textShimmer 3s infinite alternate;
  background-size: 200% 100%;
}

.modal-header p {
  margin: 0;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.85);
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.5;
}

@keyframes modalBgFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalContentEnter {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Iframe styling */
.modal-content iframe {
  display: block;
  margin: 0 auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: fadeSlideUp 0.7s 0.4s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.02);
  padding: 0 10px;
}

@keyframes fadeSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modalPulse {
  0% {
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.6),
              0 0 0 1px rgba(59, 130, 246, 0.3) inset,
              0 0 50px rgba(59, 130, 246, 0.4);
  }
  100% {
    box-shadow: 0 20px 70px rgba(0, 0, 0, 0.7),
              0 0 0 1px rgba(59, 130, 246, 0.5) inset,
              0 0 70px rgba(59, 130, 246, 0.6);
  }
}

/* Loading indicator for iframe */
.modal-content::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: 40px; /* Below the header */
  margin-left: -25px;
  border-radius: 50%;
  border: 3px solid rgba(59, 130, 246, 0.1);
  border-top-color: rgba(59, 130, 246, 0.8);
  animation: spin 1s linear infinite;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show the loading spinner until the iframe is loaded */
.modal-content iframe:not([src]) + .modal-content::after,
.modal-content iframe:not(:loaded) + .modal-content::after {
  opacity: 1;
}

/* Make responsive for mobile devices */
@media (max-width: 768px) {
  .modal-content {
    max-width: 95%;
    max-height: 85vh;
    padding-bottom: 15px;
  }
  
  .modal-header {
    padding: 20px 15px 15px;
  }
  
  .modal-header h2 {
    font-size: 1.6rem;
  }
  
  .modal-header p {
    font-size: 0.95rem;
    max-width: 95%;
  }
  
  .modal-close {
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
  }
  
  .modal-content iframe {
    height: 500px !important;
  }

  .form-container {
    padding: 0 15px 15px;
  }
  
  .custom-form {
    padding: 15px;
  }
  
  .form-input, 
  .form-textarea {
    padding: 12px 14px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-height: 90vh;
    margin: 20px 0;
  }
  
  .modal-content iframe {
    height: 450px !important;
  }
  
  .form-container {
    padding: 0 10px 15px;
  }
  
  .custom-form {
    padding: 12px;
  }
  
  .form-group label {
    font-size: 0.9rem;
    margin-bottom: 6px;
  }
  
  .form-input, 
  .form-textarea {
    padding: 10px 12px;
    font-size: 0.9rem;
  }
  
  .form-textarea {
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    max-width: 100%;
    padding: 12px 15px;
    font-size: 1rem;
  }
  
  .form-footer {
    font-size: 0.8rem;
    margin-top: 15px;
  }
  
  .arrow-left, .arrow-right {
    display: none;
  }
  
  /* Add a subtle pulse animation to the button on mobile instead */
  .cta-button {
    animation: buttonGradient 8s ease infinite,
      buttonPulse 3s infinite alternate,
      buttonMobilePulse 2s infinite alternate,
      buttonAttention 8s 2s ease-in-out infinite;
  }
  
  @keyframes buttonMobilePulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
}

/* Ensure the modal overlay adapts to small screens */
@media (max-height: 700px) {
  .modal-overlay {
    align-items: flex-start;
    padding-top: 50px;
    overflow-y: auto;
  }
  
  .modal-content {
    max-height: calc(100vh - 100px);
  }
}

/* Custom Form Styling */
.form-container {
  padding: 0 30px 30px;
  animation: fadeSlideUp 0.7s 0.4s ease-out forwards;
  opacity: 0;
  transform: translateY(10px);
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.custom-form {
  background: rgba(13, 17, 23, 0.4);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(59, 130, 246, 0.15) inset;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-form:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3),
              0 0 0 1px rgba(59, 130, 246, 0.3) inset,
              0 0 30px rgba(59, 130, 246, 0.2) inset;
}

.form-group {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}

.form-group label {
  display: block;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  text-align: left;
}

.form-input, 
.form-textarea {
  width: 100%;
  padding: 14px 18px;
  background: rgba(30, 41, 59, 0.4);
  border: 1px solid rgba(59, 130, 246, 0.2);
  border-radius: 10px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  max-width: 100%;
}

.form-input:focus, 
.form-textarea:focus {
  background: rgba(30, 41, 59, 0.6);
  border-color: rgba(59, 130, 246, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(59, 130, 246, 0.3) inset,
              0 0 20px rgba(59, 130, 246, 0.2);
  outline: none;
}

.form-input::placeholder, 
.form-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-textarea {
  min-height: 120px;
  resize: vertical;
  max-height: 200px; /* Prevent textarea from becoming too large */
}

.submit-button {
  background: linear-gradient(135deg, #3B82F6, #2563EB);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.1) inset;
  margin-top: 10px;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: auto;
  margin-right: auto;
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3), 
              0 0 0 1px rgba(255, 255, 255, 0.2) inset,
              0 0 30px rgba(59, 130, 246, 0.4);
  background: linear-gradient(135deg, #4B92FF, #3B82F6);
}

.submit-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.submit-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent 0%, 
    rgba(255, 255, 255, 0.2) 50%, 
    transparent 100%);
  transition: all 0.5s ease;
}

.submit-button:hover::before {
  left: 100%;
}

.button-text {
  display: inline-block;
  transform: translateX(8px);
  transition: all 0.3s ease;
}

.button-icon {
  margin-left: 8px;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(-10px);
  display: inline-block;
}

.submit-button:hover .button-text {
  transform: translateX(-4px);
}

.submit-button:hover .button-icon {
  opacity: 1;
  transform: translateX(0);
}

.form-footer {
  margin-top: 24px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

/* Success Message Styling */
.success-message {
  text-align: center;
  padding: 40px 20px;
  animation: fadeSlideUp 0.6s ease-out forwards;
}

.success-icon {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #3B82F6, #10B981);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;
  font-size: 40px;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.1) inset,
              0 0 30px rgba(59, 130, 246, 0.4);
  position: relative;
  animation: successIconEntrance 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards 0.3s,
             pulse 2s infinite alternate ease-in-out 1s;
  opacity: 0;
  transform: scale(0.5);
}

.success-icon::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  border: 2px solid rgba(59, 130, 246, 0.3);
  animation: ringPulse 2s infinite;
  opacity: 0;
}

.success-message h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #ffffff 0%, #e0e7ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.success-message p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 500px;
  margin: 0 auto 30px;
  line-height: 1.6;
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 12px 25px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1),
              0 0 15px rgba(59, 130, 246, 0.2);
}

.close-button:active {
  transform: translateY(1px);
}

/* Loading spinner for submit button */
.submit-button.submitting {
  cursor: not-allowed;
  background: linear-gradient(135deg, #3B82F6, #3B82F6);
  opacity: 0.9;
}

.spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

/* Hide the Google Form iframe properly */
iframe#hidden_iframe {
  display: none !important;
  width: 0;
  height: 0;
  position: absolute;
  top: -999px;
  left: -999px;
  border: 0;
  opacity: 0;
  pointer-events: none;
}

@keyframes successIconEntrance {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2),
                0 0 0 1px rgba(255, 255, 255, 0.1) inset,
                0 0 30px rgba(59, 130, 246, 0.4);
  }
  100% {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3),
                0 0 0 1px rgba(255, 255, 255, 0.2) inset,
                0 0 50px rgba(59, 130, 246, 0.6);
  }
}

/* Make success message responsive */
@media (max-width: 768px) {
  .success-message {
    padding: 30px 15px;
  }
  
  .success-icon {
    width: 70px;
    height: 70px;
    font-size: 35px;
    margin-bottom: 20px;
  }
  
  .success-message h3 {
    font-size: 1.5rem;
  }
  
  .success-message p {
    font-size: 1rem;
    margin-bottom: 25px;
  }
}

/* Ensure modal doesn't get too tall */
@media (max-height: 700px) {
  .modal-content {
    max-height: 85vh;
  }
  
  .modal-header {
    padding: 15px 20px 12px;
  }
  
  .modal-header h2 {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
  
  .form-container {
    padding: 0 20px 20px;
  }
  
  .custom-form {
    padding: 20px;
  }
  
  .form-textarea {
    min-height: 80px;
  }
}